import MainMenuBar from "../../Etc/MainMenuBar/MainMenuBar";

function CodeMod(){
    return(
        <div>
            <MainMenuBar page={"Code"} />
            <span>코드 수정 및 삭제 페이지</span>
        </div>
    )
}
export default CodeMod;